import {
	IconButton,
	Typography,
	Paper,
	Box,
	Divider,
	CircularProgress,
	Snackbar,
	Alert,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../Utils/DateFormatter';
import ExpenseComponent from './ExpenseComponent';
import { concatExpenses } from '../../Utils/ConcatExpenses';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ResizingButton from '../Shared/ResizingButton';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
	openAddExpenseDialog,
	openEditFolderDialog,
	openSendFolderDialog,
} from '../../Redux/Uislice';
import { findDateDifference, findDateRange } from '../../Utils/FindDateRange';
import { calculateTotal } from '../../Utils/CalculateTotal';
import { useTranslation } from 'react-i18next';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ApiClient from '../../Services/ApiClient';
import { ExpenseFolder } from '../../Models/ExpenseFolder';
import {
	toBaseExpenseBhExpense,
	toFlightBhExpense,
	toOtherTransportBhExpense,
} from '../../Models/Mappings';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	deleteExpense,
	postBaseExpense,
	postFlight,
	postOtherTransport,
} from '../../Redux/ExpenseFoldersSlice/ExpenseThunks';
import { BergHansenModelsExpenseM3ExpenseName } from '../../Services/SwaggerClient';
import { Profile } from '../../Models/Profile';
import { matomo } from '../../MatomoTracker';
import { formatNumberWithSpaces } from '../../Pdf/PdfDocument';

interface ExpenseFolderProps {
	expenseFolderId: number;
}

const ExpenseFolderComponent: React.FC<ExpenseFolderProps> = ({
	expenseFolderId,
}) => {
	const folder = useAppSelector((state) => {
		const expenseFolder = state.expenseFolderReducer.folders.find(
			(folder) => folder.id === expenseFolderId,
		);
		return expenseFolder;
	})!;

	const profile = useAppSelector((state) => state.profileReducer.profile);

	const { t } = useTranslation();

	const [total, setTotal] = useState<number | null>(0);

	const token = useAppSelector((state) => state.apiKey.value);

	const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);

	const [displayErrorSnackbar, setDisplayErrorSnackbar] = useState(false);

	const [hasBergHansenExpenses, setHasBergHansenExpenses] = useState(false);

	useEffect(() => {
		if (concatExpenses(folder).some((e) => e.m3OrderNumber)) {
			setHasBergHansenExpenses(true);
		} else {
			setHasBergHansenExpenses(false);
		}
	}, [folder]);

	useEffect(() => {
		const calculate = async () => {
			try {
				setTotal(null);
				const total = await calculateTotal(
					folder,

					ApiClient(token!),
					profile,
				);

				setTotal(total);
			} catch (e) {
				setTotal(null);
			}
		};
		if (concatExpenses(folder).length > 0) {
			calculate();
		} else {
			setTotal(0);
		}
	}, [folder, profile.creditCardFee]);

	const shouldDisplayDates = (folder: ExpenseFolder) => {
		return (
			folder.userSetFromDate ??
			folder.userSetEndDate ??
			concatExpenses(folder).length > 0
		);
	};

	const dispatch = useAppDispatch();

	return (
		<>
			<Paper sx={{ padding: '2rem' }}>
				<Box>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Typography variant="h6">
							{folder.description}{' '}
						</Typography>
						<IconButton
							onClick={() => {
								matomo.trackEvent(
									'Edit Folder',
									'click',
									'Edit folder button clicked',
									expenseFolderId,
								);
								return dispatch(
									openEditFolderDialog(folder.id),
								);
							}}
						>
							<EditNoteOutlinedIcon
								titleAccess={t('edit_expense')}
							/>
						</IconButton>
					</Box>

					<Typography marginBottom={'1.5rem'}>
						{formatStartDate(folder)}
						{shouldDisplayDates(folder) && ' - '}
						{formatEndDate(folder)}
					</Typography>

					{concatExpenses(folder)
						.sort((a, b) => a.id - b.id)
						.map((expense) => (
							<ExpenseComponent
								key={expense.id}
								expense={expense}
							/>
						))}
					<ResizingButton
						variant="text"
						color="primary"
						disabled={concatExpenses(folder).length >= 40}
						onClick={() =>
							dispatch(
								openAddExpenseDialog({
									selectedExpenseFolderId: folder.id,
								}),
							)
						}
						size="small"
						sx={{
							marginLeft: '0rem',
							paddingLeft: '0rem',
							marginBottom: '0.7rem',
							marginTop: '0rem',
						}}
					>
						{concatExpenses(folder).length < 40
							? t('add_expense')
							: t('max_expenses')}
						<AddIcon />
					</ResizingButton>
					<Box display={'flex'} justifyContent={'space-between'}>
						<Typography variant="body2" fontWeight={600}>
							TOTAL{' '}
							{shouldDisplayCreditCardFee(profile, folder)
								? '(' +
								  t('incl') +
								  ' ' +
								  t('currency_fee').toLowerCase() +
								  ')'
								: ''}
						</Typography>
						<Typography variant="body2" fontWeight={600}>
							{total !== null ? (
								formatNumberWithSpaces(total) +
								' ' +
								folder.currencyCode
							) : (
								<CircularProgress size={18} />
							)}
						</Typography>
					</Box>
					<Divider
						sx={{ marginTop: '0.1rem', marginBottom: '1rem' }}
					/>
				</Box>

				<Box display="flex">
					{hasBergHansenExpenses && (
						<LoadingButton
							loading={isUpdatingOrder}
							variant="text"
							sx={{ marginLeft: '-0.5rem', textAlign: 'left' }}
							onClick={async () => {
								const f = { ...folder };
								setIsUpdatingOrder(true);
								const orderNumbers = concatExpenses(folder)
									.filter((e) => e.m3OrderNumber)
									.map((exp) => exp.m3OrderNumber);
								const uniqueOrderNumbers = Array.from(
									new Set(orderNumbers),
								);
								if (uniqueOrderNumbers.length > 0) {
									console.log(uniqueOrderNumbers);
									const promises = uniqueOrderNumbers.map(
										(orderNumber) => {
											return ApiClient(
												token!,
											).expense.getExpensesOnOrderDetail(
												orderNumber!,
											);
										},
									);
									const expenses = await Promise.all(
										promises,
									);

									const bhExpenses = expenses.flatMap(
										(e) => e.data,
									);

									const orderPromises =
										uniqueOrderNumbers.map((orderNumber) =>
											ApiClient(
												token!,
											).expense.getOrderDetail(
												profile.id,
												{ orderNo: orderNumber! },
											),
										);

									const orders = await Promise.all(
										orderPromises,
									);

									bhExpenses.forEach(async (e) => {
										const order = orders.find(
											(o) =>
												o.data.orderNo ===
												e.orderNumber?.toString(),
										)!.data;
										if (
											e.type ===
											BergHansenModelsExpenseM3ExpenseName.Flight
										) {
											const flightExpense =
												toFlightBhExpense(
													e,
													folder.id,
													order.originDisp ?? '',
													order.destDisp ?? '',
												);
											await dispatch(
												postFlight(flightExpense),
											);
										} else if (
											BergHansenModelsExpenseM3ExpenseName.Taxi
										) {
											const otherTransport =
												toOtherTransportBhExpense(
													e,
													folder.id,
													order.originDisp ?? '',
													order.destDisp ?? '',
												);
											await dispatch(
												postOtherTransport(
													otherTransport,
												),
											);
										} else {
											const baseExpense =
												toBaseExpenseBhExpense(
													e,
													folder.id,
												);
											await dispatch(
												postBaseExpense(baseExpense),
											);
										}
									});

									await Promise.all(
										concatExpenses(f)
											.filter((e) => e.m3OrderNumber)
											.map(async (exp) => {
												return await dispatch(
													deleteExpense(exp.id),
												);
											}),
									);

									setIsUpdatingOrder(false);
								}
							}}
						>
							{t('update_bh_expenses')}
						</LoadingButton>
					)}

					<ResizingButton
						onClick={() => {
							if (total === null)
								return setDisplayErrorSnackbar(true);
							dispatch(openSendFolderDialog(folder.id));
						}}
						variant="contained"
						color="primary"
						size="small"
						disabled={concatExpenses(folder).length === 0}
						sx={{ marginLeft: 'auto' }}
					>
						{concatExpenses(folder).length != 0 && (
							<CheckIcon sx={{ marginRight: '0.1rem' }} />
						)}
						{concatExpenses(folder).length === 0
							? t('missing_receipt')
							: t('submit')}
					</ResizingButton>
				</Box>
			</Paper>
			<Snackbar
				open={displayErrorSnackbar}
				onClose={() => setDisplayErrorSnackbar(false)}
				autoHideDuration={6000}
			>
				<Alert severity="error">
					<Typography>
						{t('failed_getting_exchange_rates')}
					</Typography>
				</Alert>
			</Snackbar>
		</>
	);
};

export const formatStartDate = (folder: ExpenseFolder) => {
	if (folder.userSetFromDate) {
		return formatDate(folder.userSetFromDate);
	}
	if (findDateRange(folder).startDate) {
		return formatDate(findDateRange(folder).startDate);
	}
	if (folder.userSetEndDate) {
		return formatDate(folder.userSetEndDate);
	}
	return '';
};

export const formatEndDate = (folder: ExpenseFolder) => {
	if (folder.userSetEndDate) {
		return formatDate(folder.userSetEndDate);
	}
	if (findDateRange(folder).endDate) {
		return formatDate(findDateRange(folder).endDate);
	}
	if (folder.userSetFromDate) {
		return formatDate(folder.userSetFromDate);
	}
	return '';
};

export default ExpenseFolderComponent;
export function shouldDisplayCreditCardFee(
	profile: Profile,
	folder: ExpenseFolder,
) {
	return (
		profile.creditCardFee &&
		concatExpenses(folder).filter(
			(e) => e.currencyCode !== profile.currencyCode,
		).length > 0
	);
}
