/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BergHansenBootstrapperModelsMonitor {
  ok?: boolean;
  message?: string | null;
}

export interface BergHansenM3ClientModelsWebgateGetOrdersOrder {
  travellers?: BergHansenM3ClientModelsWebgateGetOrdersOrderTraveller[] | null;
  orderNo?: string | null;
  gdsPnr?: string | null;
  origin?: string | null;
  originDisp?: string | null;
  dest?: string | null;
  destDisp?: string | null;
  outward?: string | null;
  air?: boolean;
  car?: boolean;
  hotel?: boolean;
  rail?: boolean;
}

export interface BergHansenM3ClientModelsWebgateGetOrdersOrderTraveller {
  psgrFirstName?: string | null;
  psgrLastName?: string | null;
  tacNo?: string | null;
  email?: string | null;
  mobile?: string | null;
  search?: string | null;
  receipt?: boolean;
}

export interface BergHansenModelsCurrencyExchange {
  sourceCurrency?: string | null;
  targetCurrency?: string | null;
  /** @format double */
  valueInTargetCurrency?: number;
}

export interface BergHansenModelsExpenseAttachment {
  /** @format int32 */
  id?: number;
  /** @minLength 1 */
  description: string;
  /** @format int32 */
  expenseId?: number;
  data?: string | null;
  mediaType?: string | null;
  url?: string | null;
}

export interface BergHansenModelsExpenseBaseExpense {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  currencyCode?: string | null;
  /** @format int32 */
  expenseReportId?: number;
  expenseName?: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate?: string;
  /** @format date-time */
  createdAt?: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  description?: string | null;
}

export interface BergHansenModelsExpenseBhExpense {
  expenseId?: string | null;
  type?: BergHansenModelsExpenseM3ExpenseName;
  description?: string | null;
  /** @format date-time */
  date?: string;
  /** @format double */
  amount?: number;
  url?: string | null;
  data?: string | null;
  currency?: string | null;
  /** @format int32 */
  orderNumber?: number;
}

export interface BergHansenModelsExpenseCurrency {
  name?: string | null;
  code?: string | null;
}

export interface BergHansenModelsExpenseExpenseFolder {
  /** @format int32 */
  id?: number;
  description?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  userSetStartDate?: string | null;
  /** @format date-time */
  userSetEndDate?: string | null;
  /** @format date-time */
  submitDate?: string | null;
  /** @format date-time */
  createdAt?: string;
  /** @format double */
  totalAmount?: number;
  /**
   * @minLength 0
   * @maxLength 5
   */
  currencyCode?: string | null;
  /** @format int32 */
  profileId?: number;
  representations?: BergHansenModelsExpenseRepresentation[] | null;
  mileageAllowances?: BergHansenModelsExpenseMileageAllowance[] | null;
  flight?: BergHansenModelsExpenseFlight[] | null;
  baseExpense?: BergHansenModelsExpenseBaseExpense[] | null;
  otherTransports?: BergHansenModelsExpenseOtherTransport[] | null;
  status?: BergHansenModelsExpenseExpenseReportStatus;
}

export interface BergHansenModelsExpenseExpenseFolderPdf {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  profileId?: number;
  data?: string | null;
  description?: string | null;
  /** @format double */
  amount?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  createdAt?: string;
  /**
   * @minLength 0
   * @maxLength 5
   */
  currencyCode?: string | null;
}

export enum BergHansenModelsExpenseExpenseName {
  Other = "Other",
  OtherTransport = "OtherTransport",
  RentalCar = "RentalCar",
  MileageAllowance = "MileageAllowance",
  Flight = "Flight",
  Train = "Train",
  Meal = "Meal",
  Representation = "Representation",
  Hotel = "Hotel",
  Parking = "Parking",
}

export enum BergHansenModelsExpenseExpenseReportStatus {
  NotSubmitted = "NotSubmitted",
  Submitted = "Submitted",
}

export interface BergHansenModelsExpenseFlight {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  currencyCode?: string | null;
  /** @format int32 */
  expenseReportId?: number;
  expenseName?: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate?: string;
  /** @format date-time */
  createdAt?: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  startLocation?: string | null;
  endLocation?: string | null;
}

export enum BergHansenModelsExpenseM3ExpenseName {
  Flight = "Flight",
  Hotel = "Hotel",
  Other = "Other",
  Taxi = "Taxi",
  AirportTrain = "AirportTrain",
}

export interface BergHansenModelsExpenseMileageAllowance {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  currencyCode?: string | null;
  /** @format int32 */
  expenseReportId?: number;
  expenseName?: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate?: string;
  /** @format date-time */
  createdAt?: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  /** @format double */
  distanceInKm?: number;
  /** @format double */
  passengerCount?: number;
  startLocation?: string | null;
  endLocation?: string | null;
}

export interface BergHansenModelsExpenseOtherTransport {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  currencyCode?: string | null;
  /** @format int32 */
  expenseReportId?: number;
  expenseName?: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate?: string;
  /** @format date-time */
  createdAt?: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  description?: string | null;
  startLocation?: string | null;
  endLocation?: string | null;
}

export interface BergHansenModelsExpenseProfile {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  /** @format int32 */
  tacNo: number;
  sendTo?: string | null;
  /** @minLength 1 */
  email: string;
  /** @format double */
  ratePerKm: number;
  /** @format double */
  ratePerPassenger: number;
  bankAccount?: string | null;
  /** @minLength 1 */
  currencyCode: string;
  languageCode?: string | null;
  /** @format double */
  creditCardFee: number;
  expenseFolders?: BergHansenModelsExpenseExpenseFolder[] | null;
  expenseFolderPdfs?: BergHansenModelsExpenseExpenseFolderPdf[] | null;
}

export interface BergHansenModelsExpenseRepresentation {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  currencyCode?: string | null;
  /** @format int32 */
  expenseReportId?: number;
  expenseName?: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate?: string;
  /** @format date-time */
  createdAt?: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  who?: string | null;
  where?: string | null;
}

export interface BergHansenModelsExpenseSubmitDetails {
  /** @format int32 */
  folderId: number;
  pdf: BergHansenModelsExpenseExpenseFolderPdf;
  /** @minLength 1 */
  sendTo: string;
}

export interface ExpenseExpenseFolderPdf {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  profileId?: number;
  data?: string | null;
  description?: string | null;
  /** @format double */
  amount?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  createdAt?: string;
  /**
   * @minLength 0
   * @maxLength 5
   */
  currencyCode?: string | null;
  source?: ExpenseSource;
}

export enum ExpenseSource {
  TravelExpense = "TravelExpense",
  Expense = "Expense",
}

export interface ExpenseUtilsExpenseAttachmentDataValid {
  isValid?: boolean;
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/Expense";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Expense
 * @version v1
 * @baseUrl /Expense
 * @contact Berg-Hansen Reisebureau AS (https://dev.azure.com/berg-hansen/Berg-Hansen%20Developer)
 *
 * Through this API you can view and update expense data
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  expense = {
    /**
     * No description
     *
     * @tags Expense
     * @name GetProfileDetail
     * @request GET:/Expense/GetProfile/{profileId}
     * @secure
     */
    getProfileDetail: (profileId: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseProfile, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetProfile/${profileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetProfileFromTacNoDetail
     * @request GET:/Expense/GetProfileFromTacNo/{tacNo}
     * @secure
     */
    getProfileFromTacNoDetail: (tacNo: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseProfile, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetProfileFromTacNo/${tacNo}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostProfileCreate
     * @request POST:/Expense/PostProfile
     * @secure
     */
    postProfileCreate: (data: BergHansenModelsExpenseProfile, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseProfile, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostProfile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutProfileUpdate
     * @request PUT:/Expense/PutProfile
     * @secure
     */
    putProfileUpdate: (data: BergHansenModelsExpenseProfile, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutProfile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpenseFoldersDetail
     * @request GET:/Expense/GetExpenseFolders/{profileId}
     * @secure
     */
    getExpenseFoldersDetail: (profileId: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpenseFolders/${profileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostExpenseFolderCreate
     * @request POST:/Expense/PostExpenseFolder
     * @secure
     */
    postExpenseFolderCreate: (data: BergHansenModelsExpenseExpenseFolder, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostExpenseFolder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpenseFolderDetail
     * @request GET:/Expense/GetExpenseFolder/{folderId}
     * @secure
     */
    getExpenseFolderDetail: (folderId: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpenseFolder/${folderId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutExpenseFolderUpdate
     * @request PUT:/Expense/PutExpenseFolder
     * @secure
     */
    putExpenseFolderUpdate: (data: BergHansenModelsExpenseExpenseFolder, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutExpenseFolder`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name DeleteExpenseFolderDelete
     * @request DELETE:/Expense/DeleteExpenseFolder/{folderId}
     * @secure
     */
    deleteExpenseFolderDelete: (folderId: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/DeleteExpenseFolder/${folderId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostFlightCreate
     * @request POST:/Expense/PostFlight
     * @secure
     */
    postFlightCreate: (data: BergHansenModelsExpenseFlight, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseFlight, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostFlight`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostBaseExpenseCreate
     * @request POST:/Expense/PostBaseExpense
     * @secure
     */
    postBaseExpenseCreate: (data: BergHansenModelsExpenseBaseExpense, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseBaseExpense, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostBaseExpense`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostOtherTransportCreate
     * @request POST:/Expense/PostOtherTransport
     * @secure
     */
    postOtherTransportCreate: (data: BergHansenModelsExpenseOtherTransport, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseOtherTransport, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostOtherTransport`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostRepresentationCreate
     * @request POST:/Expense/PostRepresentation
     * @secure
     */
    postRepresentationCreate: (data: BergHansenModelsExpenseRepresentation, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseRepresentation, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostRepresentation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostMileageAllowanceCreate
     * @request POST:/Expense/PostMileageAllowance
     * @secure
     */
    postMileageAllowanceCreate: (data: BergHansenModelsExpenseMileageAllowance, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseMileageAllowance, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostMileageAllowance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutRepresentationUpdate
     * @request PUT:/Expense/PutRepresentation
     * @secure
     */
    putRepresentationUpdate: (data: BergHansenModelsExpenseRepresentation, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutRepresentation`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutBaseExpenseUpdate
     * @request PUT:/Expense/PutBaseExpense
     * @secure
     */
    putBaseExpenseUpdate: (data: BergHansenModelsExpenseBaseExpense, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutBaseExpense`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutOtherTransportUpdate
     * @request PUT:/Expense/PutOtherTransport
     * @secure
     */
    putOtherTransportUpdate: (data: BergHansenModelsExpenseOtherTransport, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutOtherTransport`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutMileageAllowanceUpdate
     * @request PUT:/Expense/PutMileageAllowance
     * @secure
     */
    putMileageAllowanceUpdate: (data: BergHansenModelsExpenseMileageAllowance, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutMileageAllowance`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PutFlightUpdate
     * @request PUT:/Expense/PutFlight
     * @secure
     */
    putFlightUpdate: (data: BergHansenModelsExpenseFlight, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PutFlight`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostAttachmentCreate
     * @request POST:/Expense/PostAttachment
     * @secure
     */
    postAttachmentCreate: (data: BergHansenModelsExpenseAttachment, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseAttachment, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostAttachment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name DeleteExpenseDelete
     * @request DELETE:/Expense/DeleteExpense/{expenseId}
     * @secure
     */
    deleteExpenseDelete: (expenseId: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/DeleteExpense/${expenseId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name PostExpenseFolderPdfCreate
     * @request POST:/Expense/PostExpenseFolderPdf
     * @secure
     */
    postExpenseFolderPdfCreate: (data: ExpenseExpenseFolderPdf, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/PostExpenseFolderPdf`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name SubmitCreate
     * @request POST:/Expense/Submit
     * @secure
     */
    submitCreate: (data: BergHansenModelsExpenseSubmitDetails, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/Submit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpenseFolderPdfsDetail
     * @request GET:/Expense/GetExpenseFolderPdfs/{profileId}
     * @secure
     */
    getExpenseFolderPdfsDetail: (profileId: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpenseFolderPdfs/${profileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetCurrenciesList
     * @request GET:/Expense/GetCurrencies
     * @secure
     */
    getCurrenciesList: (params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseCurrency[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetCurrencies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExchangeList
     * @request GET:/Expense/GetExchange
     * @secure
     */
    getExchangeList: (
      query?: {
        sourceCurrencyCode?: string;
        targetCurrencyCode?: string;
        /** @format double */
        amount?: number;
        /** @format date-time */
        date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BergHansenModelsCurrencyExchange, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExchange`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpensesOnOrderDetail
     * @request GET:/Expense/GetExpensesOnOrder/{orderNo}
     * @secure
     */
    getExpensesOnOrderDetail: (orderNo: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseBhExpense[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpensesOnOrder/${orderNo}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name AddProcessedOrderCreate
     * @request POST:/Expense/AddProcessedOrder/{orderId}
     * @secure
     */
    addProcessedOrderCreate: (orderId: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/AddProcessedOrder/${orderId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetNonProcessedOrderDetail
     * @request GET:/Expense/GetNonProcessedOrder/{profileId}
     * @secure
     */
    getNonProcessedOrderDetail: (
      profileId: number,
      query?: {
        /** @format int32 */
        orderCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BergHansenM3ClientModelsWebgateGetOrdersOrder[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetNonProcessedOrder/${profileId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetOrderDetail
     * @request GET:/Expense/GetOrder/{profileId}
     * @secure
     */
    getOrderDetail: (
      profileId: number,
      query?: {
        /** @format int32 */
        orderNo?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BergHansenM3ClientModelsWebgateGetOrdersOrder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetOrder/${profileId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpenseFolderPdfsTravelexpenseDetail
     * @request GET:/Expense/GetExpenseFolderPdfsTravelexpense/{tacNo}
     * @secure
     */
    getExpenseFolderPdfsTravelexpenseDetail: (tacNo: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpenseFolderPdfsTravelexpense/${tacNo}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpenseFolderPdfTravelExpenseDetail
     * @request GET:/Expense/GetExpenseFolderPdfTravelExpense/{pdfId}
     * @secure
     */
    getExpenseFolderPdfTravelExpenseDetail: (pdfId: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpenseFolderPdfTravelExpense/${pdfId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetExpenseFolderPdfDetail
     * @request GET:/Expense/GetExpenseFolderPdf/{pdfId}
     * @secure
     */
    getExpenseFolderPdfDetail: (pdfId: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetExpenseFolderPdf/${pdfId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name DeleteAttachmentDelete
     * @request DELETE:/Expense/DeleteAttachment/{attachmentId}
     * @secure
     */
    deleteAttachmentDelete: (attachmentId: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/DeleteAttachment/${attachmentId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name ValidateAttachmentDataCreate
     * @request POST:/Expense/ValidateAttachmentData
     * @secure
     */
    validateAttachmentDataCreate: (data: BergHansenModelsExpenseAttachment, params: RequestParams = {}) =>
      this.request<ExpenseUtilsExpenseAttachmentDataValid, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/ValidateAttachmentData`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expense
     * @name GetAttachmentDetail
     * @request GET:/Expense/GetAttachment/{attachmentId}
     * @secure
     */
    getAttachmentDetail: (attachmentId: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseAttachment, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/GetAttachment/${attachmentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  monitor = {
    /**
     * No description
     *
     * @tags Monitor
     * @name MonitorList
     * @request GET:/Monitor
     * @secure
     */
    monitorList: (params: RequestParams = {}) =>
      this.request<BergHansenBootstrapperModelsMonitor, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Monitor`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
