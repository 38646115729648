import { useState } from 'react';
import { Box } from '@mui/material';
import ResizingButton from '../Shared/ResizingButton';
import LoadingButton from '../Shared/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
	postBaseExpense,
	postFlight,
} from '../../Redux/ExpenseFoldersSlice/ExpenseThunks';
import { closeAddExpenseDialog } from '../../Redux/Uislice';
import { useTranslation } from 'react-i18next';
import BhTripsTable from './BhTripsTable';
import ApiClient from '../../Services/ApiClient';
import { BergHansenModelsExpenseM3ExpenseName as M3ExpenseName } from '../../Services/SwaggerClient';
import {
	toBaseExpenseBhExpense,
	toFlightBhExpense,
} from '../../Models/Mappings';
import {
	BhOrdersStatus,
	getBhOrders,
	processOrder,
} from '../../Redux/BhExpensesSlice';

const BhTrips = () => {
	const [checked, setChecked] = useState<number[]>([]);
	const [isAddingExpenses, setIsAddingExpenses] = useState(false);

	const dispatch = useAppDispatch();

	const bhExpensesReducer = useAppSelector(
		(state) => state.bhExpensesReducer,
	);

	const profile = useAppSelector((state) => state.profileReducer.profile);

	const selectedExpenseFolderId = useAppSelector(
		(state) => state.uiReducer.selectedExpenseFolderId,
	);

	const token = useAppSelector((state) => state.apiKey.value);

	const closeDialog = () => {
		dispatch(closeAddExpenseDialog());
	};

	const { t } = useTranslation();

	const postBhExpenses = () => {
		const o = bhExpensesReducer.orders
			.filter((order) => checked.includes(parseInt(order.orderNo!)))
			.map((order) => {
				const apiClient = ApiClient(token!);
				return apiClient.expense
					.getExpensesOnOrderDetail(parseInt(order.orderNo!))
					.then((expenses) => {
						return Promise.all(
							expenses.data.map((expense) => {
								if (
									expense.type ===
										M3ExpenseName.AirportTrain ||
									expense.type === M3ExpenseName.Hotel ||
									expense.type === M3ExpenseName.Taxi ||
									expense.type === M3ExpenseName.Other
								) {
									return dispatch(
										postBaseExpense(
											toBaseExpenseBhExpense(
												expense,
												selectedExpenseFolderId!,
											),
										),
									);
								} else if (
									expense.type === M3ExpenseName.Flight
								) {
									return dispatch(
										postFlight(
											toFlightBhExpense(
												expense,
												selectedExpenseFolderId!,
												order.originDisp ?? '',
												order.destDisp ?? '',
											),
										),
									);
								}
							}),
						);
					})
					.then(() =>
						dispatch(
							processOrder(parseInt(order.orderNo!)),
						).unwrap(),
					);
			});
		return Promise.all(o);
	};

	return (
		<Box>
			<BhTripsTable
				bergHansenExpenses={bhExpensesReducer.orders}
				handleToggle={(value: number) => {
					setChecked([...checked, value]);
				}}
			/>

			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				marginTop={'1rem'}
			>
				<LoadingButton
					onClick={() =>
						dispatch(
							getBhOrders({
								orderCount: bhExpensesReducer.orders.length + 6,
								profileId: profile.id,
							}),
						)
					}
					variant="text"
					color="primary"
					loading={
						bhExpensesReducer.status === BhOrdersStatus.Getting
					}
				>
					{t('load_more_orders')}
				</LoadingButton>
				<Box>
					<ResizingButton
						variant="text"
						onClick={closeDialog}
						style={{
							color: 'rgb(97, 97, 97)',
							fontWeight: 500,
						}}
					>
						{t('cancel')}
					</ResizingButton>
					<LoadingButton
						onClick={async () => {
							setIsAddingExpenses(true);
							postBhExpenses().then(() => {
								closeDialog();
								setIsAddingExpenses(false);
							});
						}}
						variant="contained"
						color="primary"
						loading={isAddingExpenses}
					>
						{t('add_expense')}
					</LoadingButton>
				</Box>
			</Box>
		</Box>
	);
};

export default BhTrips;
