import { Typography, Box, Stack, Alert, Snackbar, Link } from '@mui/material';
import { Navigate, Link as RouterLink } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ResizingButton from '../Components/Shared/ResizingButton';
import AddExpenseFolderDialog from '../Components/Home/AddExpenseFolderDialog';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import {
	openAddFolderDialog,
	setEmailSuccessFullySent,
} from '../Redux/Uislice';
import EditExpenseDialog from '../Components/Home/EditExpenseDialog';
import AddExpenseDialogComponent from '../Components/Home/AddExpenseDialogComponent';
import SendFolderDialogComponent from '../Components/Home/SendFolderDialogComponent';
import EditExpenseFolderDialogComponent from '../Components/Home/EditExpenseFolderDialog';
import { useTranslation } from 'react-i18next';
import { findDateDifference } from '../Utils/FindDateRange';
import ExpenseFolderComponent from '../Components/Home/ExpenseFolderComponent';
import HistoryIcon from '@mui/icons-material/History';

const Home = () => {
	const expenseFolderSlice = useAppSelector(
		(state) => state.expenseFolderReducer,
	);

	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const shouldGoToSettings = useAppSelector(
		(state) => state.uiReducer.isFirstTimeLogin,
	);

	const successfullSend = useAppSelector(
		(state) => state.uiReducer.emailSuccessFullySent,
	);

	if (shouldGoToSettings) return <Navigate to="/settings" />;

	return (
		<Box>
			<Typography variant="h6">{t('welcome_message')}</Typography>
			<Typography variant="body1">{t('welcome_text')} </Typography>
			<ResizingButton
				onClick={() => dispatch(openAddFolderDialog())}
				variant="text"
				sx={{
					marginLeft: '0rem',
					paddingLeft: '0rem',
					marginTop: '3.2rem',
				}}
			>
				<AddIcon
					sx={{
						marginLeft: '0rem',
						paddingLeft: '0rem',
					}}
				/>
				{t('new_folder')}
			</ResizingButton>
			<Box display="flex" alignItems={'center'}>
				<Typography variant="h6">{t('expense_folders')}</Typography>
				<Link
					component={RouterLink}
					to="/history"
					sx={{ color: 'black' }}
					marginLeft={'auto'}
				>
					<ResizingButton variant="text" sx={{ textAlign: 'right' }}>
						{t('previously_submitted')}
						<HistoryIcon
							sx={{
								marginLeft: '0.5rem',
								size: 'small',
							}}
						/>
					</ResizingButton>
				</Link>
			</Box>

			{!expenseFolderSlice.folders ||
				(expenseFolderSlice.folders.length === 0 && (
					<Typography marginTop={'1rem'}>
						{t('no_folders')}
					</Typography>
				))}
			<Stack spacing={2} marginTop={'1rem'}>
				{expenseFolderSlice.folders
					.slice()
					.sort(findDateDifference)
					.map((folder) => (
						<ExpenseFolderComponent
							expenseFolderId={folder.id}
							key={folder.id}
						/>
					))}
			</Stack>
			<EditExpenseDialog />
			<AddExpenseDialogComponent />
			<AddExpenseFolderDialog />
			<SendFolderDialogComponent />
			<EditExpenseFolderDialogComponent />
			<Snackbar
				open={successfullSend === true}
				onClose={() => dispatch(setEmailSuccessFullySent(null))}
			>
				<Alert severity="success">{t('email_sent')}</Alert>
			</Snackbar>
			<Snackbar
				open={successfullSend === false}
				onClose={() => dispatch(setEmailSuccessFullySent(null))}
			>
				<Alert severity="error">{t('email_not_sent')}</Alert>
			</Snackbar>
		</Box>
	);
};

export default Home;
