import { Box } from '@mui/material';
import {
	Flight,
	Representation,
	MileageAllowance,
	ExpenseUnion,
	BaseExpense,
	OtherTransport,
	ExpenseName,
} from '../../Models/Expense';
import { useEffect } from 'react';
import { Attachment } from '../../Models/Attachment';
import AddDialogButtons from '../Shared/AddDialogButtons';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';

import {
	postBaseExpense,
	postFlight,
	postMileageAllowance,
	postOtherTransport,
	postRepresentation,
} from '../../Redux/ExpenseFoldersSlice/ExpenseThunks';
import {
	addBeganExpense,
	closeAddExpenseDialog,
	removeBeganExpense,
	setBeganExpense,
} from '../../Redux/Uislice';
import { Status } from '../../Redux/ExpenseFoldersSlice/ExpenseFoldersSlice';
import { initExpense } from '../../Utils/InitExpense';
import ExpenseFormFields from '../Shared/ExpenseFormFields';
import AttachmentsBox from './AttachmentsBox';

export const fileToBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
		reader.readAsDataURL(file);
	});
};

const AddExpenseFormComponent = () => {
	const beganExpense = useAppSelector((state) =>
		state.uiReducer.beganExpenses.find(
			(e) =>
				e.expenseFolderId === state.uiReducer.selectedExpenseFolderId,
		),
	);

	const selectedExpenseFolderId = useAppSelector(
		(state) => state.uiReducer.selectedExpenseFolderId,
	)!;

	const expenseFolder = useAppSelector((state) =>
		state.expenseFolderReducer.folders.find(
			(f) => f.id === state.uiReducer.selectedExpenseFolderId,
		),
	);

	useEffect(() => {
		if (!beganExpense)
			dispatch(addBeganExpense(initExpense(selectedExpenseFolderId)));
	}, [beganExpense]);

	const dispatch = useAppDispatch();

	const isAdding = useAppSelector(
		(state) => state.expenseFolderReducer.expenseStatus === Status.Fetching,
	);

	const handleAttachmentUpload = async (file: File) => {
		fileToBase64(file).then((base64) => {
			const attachment: Attachment = {
				id: 0,
				description: file.name,
				data: base64,
				mediaType: file.type,
				expenseId: beganExpense?.id ?? 0,
			};
			dispatch(
				setBeganExpense({
					...beganExpense,
					attachments: [
						...(beganExpense?.attachments ?? []),
						attachment,
					],
				}),
			);
		});
	};

	const handleAddExpense = async (
		e: {
			preventDefault: () => void;
		},
		exp: ExpenseUnion,
	) => {
		e.preventDefault();
		switch (exp.expenseName) {
			case ExpenseName.Flight:
				await dispatch(postFlight(exp as Flight));
				break;
			case ExpenseName.Representation:
				await dispatch(postRepresentation(exp as Representation));
				break;
			case ExpenseName.MileageAllowance:
				await dispatch(postMileageAllowance(exp as MileageAllowance));
				break;
			case ExpenseName.OtherTransport:
				await dispatch(postOtherTransport(exp as OtherTransport));
				break;
			default:
				await dispatch(postBaseExpense(exp as BaseExpense));
		}
		dispatch(removeBeganExpense(exp.expenseFolderId));
		dispatch(closeAddExpenseDialog());
	};

	if (!beganExpense) return null;

	return (
		<Box>
			<AttachmentsBox
				handleAttachmentUpload={handleAttachmentUpload}
				expense={beganExpense}
				setExpense={(expense) => dispatch(setBeganExpense(expense))}
			/>

			<form
				onSubmit={async (e) => await handleAddExpense(e, beganExpense)}
			>
				<ExpenseFormFields
					expense={beganExpense}
					setExpense={(e) => dispatch(setBeganExpense(e))}
				/>
				<AddDialogButtons
					closeDialog={() => dispatch(closeAddExpenseDialog())}
					isAdding={isAdding}
					textCode="add_expense"
				/>
			</form>
		</Box>
	);
};

export default AddExpenseFormComponent;
