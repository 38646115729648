import {
	Dialog,
	DialogContent,
	DialogTitle,
	useMediaQuery,
} from '@mui/material';
import CloseIconButtonComponent from '../Shared/CloseIconButtonComponent';
import { useAppSelector, useAppDispatch } from '../../Redux/Hooks';
import { closeEditExpenseDialog } from '../../Redux/Uislice';
import EditExpenseFormComponent from './EditExpenseFormComponent';
import { useTranslation } from 'react-i18next';

const EditExpenseDialog = () => {
	const open = useAppSelector(
		(state) => state.uiReducer.isEditExpenseDialogOpen,
	);
	const isOnMobile = useMediaQuery('(max-width:600px)');
	const dispatch = useAppDispatch();
	const closeDialog = () => {
		dispatch(closeEditExpenseDialog());
	};
	const { t } = useTranslation();
	return (
		<Dialog open={open} onClose={closeDialog} fullScreen={isOnMobile}>
			<DialogTitle>{t('edit_receipt')}</DialogTitle>
			<DialogContent>
				<EditExpenseFormComponent />
			</DialogContent>
			<CloseIconButtonComponent onClick={closeDialog} />
		</Dialog>
	);
};

export default EditExpenseDialog;
