import { DeleteOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { downloadFile, base64ToFile } from '../../Utils/File';
import { ExpenseUnion } from '../../Models/Expense';
import ApiClient from '../../Services/ApiClient';
import { useAppSelector } from '../../Redux/Hooks';
interface AttachmentListProps {
	expense: ExpenseUnion;
	setExpense: (expense: ExpenseUnion) => void;
}

const AttachmentList = (props: AttachmentListProps) => {
	const { expense, setExpense } = props;
	const token = useAppSelector((state) => state.apiKey.value);
	return (
		<Box>
			{expense?.attachments?.map((attachment, index) => (
				<Box key={index} alignItems={'center'} display={'flex'}>
					<Typography
						onClick={() => {
							ApiClient(token!)
								.expense.getAttachmentDetail(attachment.id)
								.then((res) => {
									downloadFile(
										base64ToFile(
											res.data.data!,
											attachment.description ??
												`attachment.${
													attachment.mediaType.split(
														'/',
													)[1]
												}`,
										),
									);
								})
								.catch((err) => {
									console.log(err);
									if (attachment.data) {
										downloadFile(
											base64ToFile(
												attachment.data,
												attachment.description ??
													`attachment.${
														attachment.mediaType.split(
															'/',
														)[1]
													}`,
											),
										);
									}
								});
						}}
						sx={{
							cursor: 'pointer',
						}}
					>
						{formatFileName(attachment.description)}
					</Typography>
					<IconButton
						onClick={() =>
							setExpense({
								...expense,
								attachments: expense.attachments?.filter(
									(a) => a !== attachment,
								),
							})
						}
					>
						<DeleteOutlined />
					</IconButton>
					<IconButton
						onClick={() => {
							ApiClient(token!)
								.expense.getAttachmentDetail(attachment.id)
								.then((res) => {
									downloadFile(
										base64ToFile(
											res.data.data!,
											attachment.description ??
												`attachment.${
													attachment.mediaType.split(
														'/',
													)[1]
												}`,
										),
									);
								})
								.catch((err) => {
									console.log(err);
									downloadFile(
										base64ToFile(
											attachment.data,
											attachment.description ??
												`attachment.${
													attachment.mediaType.split(
														'/',
													)[1]
												}`,
										),
									);
								})
								.finally(() => {});
						}}
					>
						<FileDownloadOutlined />
					</IconButton>
				</Box>
			))}
		</Box>
	);
};

const formatFileName = (fileName: string): string => {
	const parts = fileName.split('.');
	if (parts.length < 2) return fileName;

	const namePart = parts[0];
	const extensionPart = parts[parts.length - 1];

	if (namePart.length <= 10) return fileName;

	const formattedName = `${namePart.substring(0, 20)}...${namePart.substring(
		namePart.length - 3,
	)}`;
	return `${formattedName}.${extensionPart}`;
};

export default AttachmentList;
