import {
	TableBody,
	TableRow,
	TableCell,
	Table,
	TableHead,
} from '@mui/material';
import { BergHansenM3ClientModelsWebgateGetOrdersOrder } from '../../Services/SwaggerClient';
import BhTableRow from './BhTableRow';
import { t } from 'i18next';

interface MobileTableProps {
	bergHansenExpenses: BergHansenM3ClientModelsWebgateGetOrdersOrder[];
	handleToggle: (id: number) => void;
}
const BhTripsTable = (props: MobileTableProps) => {
	const { bergHansenExpenses, handleToggle } = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell padding="checkbox"></TableCell>
					<TableCell>{t('destination')}</TableCell>
					<TableCell>{t('outward_date')}</TableCell>
					<TableCell align="right"></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{bergHansenExpenses.map((expense) => (
					<BhTableRow
						key={expense.orderNo}
						bergHansenOrder={expense}
						handleToggle={handleToggle}
					/>
				))}
			</TableBody>
		</Table>
	);
};

export default BhTripsTable;
