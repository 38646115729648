import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PdfBox } from '../Components/History/PdfBox';
import { formatDate } from '../Utils/DateFormatter';
import Loading from './Loading';
import { useAppSelector } from '../Redux/Hooks';
import { useTranslation } from 'react-i18next';
import { findDateDifference } from '../Utils/FindDateRange';
import BackToHomeLink from '../Components/Shared/BackToHomeLink';

export const History: React.FC = () => {
	const pdfs = useAppSelector(
		(state) => state.expenseFolderPdfReducer.pdfsExpense,
	);
	const pdfsOld = useAppSelector(
		(state) => state.expenseFolderPdfReducer.pdfsOld,
	);
	const { t } = useTranslation();
	if (!pdfs) return <Loading />;

	return (
		<>
			<BackToHomeLink />
			<Typography variant="h5" marginBottom={2} fontWeight={600}>
				{t('sent_expenses')}
			</Typography>
			<Typography variant="body1" marginBottom={2}>
				{t('sent_expenses_text')}{' '}
			</Typography>
			<Stack spacing={2}>
				{[...pdfs, ...pdfsOld]
					.slice()
					.sort(findDateDifference)
					.map((pdf) => (
						<PdfBox
							key={
								pdf.id +
								pdf.amount +
								pdf.description +
								pdf.startDate
							}
							title={pdf.description}
							dateRange={
								formatDate(pdf.startDate) +
								' - ' +
								formatDate(pdf.endDate)
							}
							total={pdf.amount}
							expenseFolderPdf={pdf}
						/>
					))}
			</Stack>
		</>
	);
};

export default History;
