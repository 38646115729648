import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import { ExpenseFolder } from '../../Models/ExpenseFolder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CloseIconButtonComponent from '../Shared/CloseIconButtonComponent';
import EditDialogButtons from '../Shared/EditDialogButtons';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { putExpenseFolder } from '../../Redux/ExpenseFoldersSlice/ExpenseFolderThunks';
import { closeEditFolderDialog } from '../../Redux/Uislice';
import { deleteExpenseFolder } from '../../Redux/ExpenseFoldersSlice/ExpenseThunks';
import { Status } from '../../Redux/ExpenseFoldersSlice/ExpenseFoldersSlice';
import _ from 'lodash';
import { findDateRange } from '../../Utils/FindDateRange';
import { formatDateInput } from '../../Utils/DateFormatter';
import { useTranslation } from 'react-i18next';

const EditExpenseFolderDialogComponent = () => {
	const [expenseFolderDraft, setExpenseFolderDraft] =
		useState<ExpenseFolder>();

	const expenseFolderToEdit = useAppSelector((state) => {
		if (state.uiReducer.expenseFolderToEditId) {
			return state.expenseFolderReducer.folders.find(
				(folder) => folder.id === state.uiReducer.expenseFolderToEditId,
			);
		}
	});

	const { calculatedStartDate, calculatedEndDate } = useAppSelector(
		(state) => {
			const folder = state.expenseFolderReducer.folders.find(
				(folder) => folder.id === state.uiReducer.expenseFolderToEditId,
			);
			const { startDate, endDate } = (folder &&
				findDateRange(folder)) || { startDate: null, endDate: null };

			return {
				calculatedStartDate: startDate,
				calculatedEndDate: endDate,
			};
		},
	)!;

	const isLoading = useAppSelector(
		(state) => state.expenseFolderReducer.expenseFolderStatus,
	);
	const open = useAppSelector(
		(state) => state.uiReducer.isEditExpenseFolderDialogOpen,
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		setExpenseFolderDraft(expenseFolderToEdit);
	}, [expenseFolderToEdit]);

	const handleFromDateChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setExpenseFolderDraft({
			...expenseFolderDraft!,
			userSetFromDate: new Date(event.target.value).toISOString(),
		});
	};

	const handleFolderNameChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setExpenseFolderDraft({
			...expenseFolderDraft!,
			description: event.target.value,
		});
	};

	const handleEndDateChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setExpenseFolderDraft({
			...expenseFolderDraft!,
			userSetEndDate: new Date(event.target.value).toISOString(),
		});
	};

	const submitFolderForm = async (
		event: React.FormEvent<HTMLFormElement>,
	) => {
		event.preventDefault();
		try {
			await dispatch(putExpenseFolder(expenseFolderDraft!)).unwrap();
			closeDialog();
		} catch {
			//display snackbar that tells the user that it failed?
		}
	};

	const closeDialog = () => {
		dispatch(closeEditFolderDialog());
	};

	const getFromDate = (folder: ExpenseFolder) => {
		return folder.userSetFromDate ?? calculatedStartDate;
	};

	const getEndDate = (folder: ExpenseFolder) => {
		return folder.userSetEndDate ?? calculatedEndDate;
	};

	const translation = useTranslation();

	if (!expenseFolderDraft) return null;

	return (
		<Dialog open={open} onClose={closeDialog}>
			<CloseIconButtonComponent onClick={closeDialog} />
			<DialogTitle
				display={'flex'}
				alignItems={'center'}
				marginTop="0rem"
				paddingTop="0rem"
			>
				<FolderOutlinedIcon sx={{ marginRight: '0.4rem' }} />
				{translation.t('edit_expense_folder')}
			</DialogTitle>

			<DialogContent>
				<form onSubmit={async (e) => await submitFolderForm(e)}>
					<TextField
						autoFocus
						id="name"
						label={translation.t('name')}
						type="text"
						value={expenseFolderDraft?.description}
						onChange={handleFolderNameChange}
						required
					/>
					<TextField
						label={translation.t('from_date')}
						type="date"
						value={
							getFromDate(expenseFolderDraft)
								? formatDateInput(
										getFromDate(expenseFolderDraft)!,
								  )
								: formatDateInput(new Date().toISOString())
						}
						onChange={handleFromDateChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField
						label={translation.t('to_date')}
						type="date"
						value={
							getEndDate(expenseFolderDraft)
								? formatDateInput(
										getEndDate(expenseFolderDraft)!,
								  )
								: formatDateInput(new Date().toISOString())
						}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={handleEndDateChange}
					/>
					<EditDialogButtons
						deleteItem={() => {
							dispatch(deleteExpenseFolder(expenseFolderDraft));
							closeDialog();
						}}
						buttonText="delete_expense_folder"
						isSaving={isLoading === Status.Fetching}
						isEditButtonDisabled={_.isEqual(
							expenseFolderDraft,
							expenseFolderToEdit,
						)}
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default EditExpenseFolderDialogComponent;
